.mehmehnomics-container {
  width: 100%;
  background-color: #63ecff;
  margin-bottom: 5rem;
}

.content-meh {
  background-image: url(../../assets/Rectangle\ 7.png);
  background-repeat: no-repeat;
  max-width: 1050px;
  height: 721px;
  margin: 3rem auto 1rem auto;
  position: relative;
  background-size: cover;
  padding: 0 0 0 2rem;
  border-radius: 1rem;
}

.content-meh h1 {
  text-align: center;
  font-weight: 400;
  font-size: 96px;
  line-height: 80px;
  color: #ff93c7;
  padding: 2.5rem 1rem;
}

.boxes-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding-left: 2rem;
}

.box-container {
  background-image: url(../../assets/shape4.svg);
  max-width: 355px;
  width: 100%;
  height: 136px;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}

.box-container h3 {
  font-size: 36px;
  font-weight: 400;
  line-height: 32px;
  color: #2148ff;
  padding-left: 2rem;
}

.box-container h4 {
  font-size: 36px;
  font-weight: 400;
  line-height: 32px;
  color: #02187e;
  padding-left: 2rem;
}

.boy-image {
  position: absolute;
  bottom: -3rem;
  right: 3rem;
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 640px) {
  /* .content-meh {
        height: 350vh;
    } */

  .boy-image {
    width: 160px;
    bottom: 0rem;
    right: 1rem;
  }
}

@media only screen and (max-device-width: 810px) and (orientation: portrait) {
  .content-meh {
    height: 135vh;
  }
}
@media only screen and (max-device-width: 640px) and (orientation: portrait) {
  .content-meh {
    height: 150vh;
  }
}

@media only screen and (max-device-width: 810px) and (orientation: landscape) {
  .content-meh {
    height: 160vh;
  }
}
@media only screen and (max-device-width: 640px) and (orientation: landscape) {
  .content-meh {
    height: 180vh;
  }
}
@media only screen and (max-width: 400px) {
  .content-meh h1 {
    font-size: 75px;
  }

  .content-meh {
    background-image: url(../../assets/mehmeh-mobile-bg.png);
    background-repeat: no-repeat;
    max-width: 1050px;
    margin: 3rem auto 1rem auto;
    position: relative;
    background-size: cover;
    padding: 0 0 0 1rem;
  }
}
