.footer1 {
    padding: 3rem;
}

.footer-container {
    background-image: url(../../assets/footer.svg);
    width: 100%;
    min-height: 120px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 4rem auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 1rem;
}

.footer-boy {
    position: absolute;
    top: -6rem;
    left: 2.5rem;
}

.footer-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    gap: 1rem;
}

.footer-desc h2 {
    color: #2148FF;
    font-weight: 300;
    font-size: 36px;
    line-height: 32px;
}

.footer-desc span {
    line-height: 32px;
    color: #2148FF;
    font-weight: 400;
    font-size: 48px;
}

.social-links-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 1rem;
}

.footer-desc {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.social-box {
    background-image: url(../../assets/social-bg.svg);
    background-repeat: no-repeat;
    width: 70px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1250px) {
    .footer-container {
        background-size: cover;
    }
}

@media only screen and (max-width: 768px) {
    .footer-container {
        background-image: url(../../assets/Rectangle\ 8.svg);
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }

    .footer-content {
        flex-direction: column;
        gap: 3rem;
        padding: 5rem 1rem 1rem 1rem;
    }

    .social-links-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding-right: 0;
    }

    .footer-desc {
        align-items: flex-start;
        flex-direction: column;
    }

    .footer1 {
        padding: .5rem;
    }

    .footer-boy {
        position: absolute;
        top: -5rem;
        left: 1.5rem;
    }
}