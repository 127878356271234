.hero-container {
  background-color: #63ecff;
  width: 100%;
}

.content-container {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 1rem 0.5rem 0 0.5rem;
}

.title-container {
  text-align: center;
  margin: 2rem auto;
  background-image: url(../../assets/shape.svg);
  background-repeat: no-repeat;
  background-position: center;
  max-width: 407px;
  height: 161px;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  justify-content: center;
}

.title-mehmeh {
  color: #ff93c7;
  font-size: 96px;
  line-height: 80px;
  font-weight: 400;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -8rem;
}

.image-container {
  margin-top: -5rem;
  margin-right: 1rem;
}

.desc-container {
  background-image: url(../../assets/shape2.svg);
  background-repeat: no-repeat;
  max-width: 660px;
  height: 480px;
  display: flex;
  padding: 0rem 1.5rem 1rem 4.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
  background-size: cover;
  border-radius: 1rem;
}

.desc-container .desc {
  margin-top: -5rem;
  max-width: 546px;
  font-size: 28px;
  font-weight: 300;
  line-height: 33px;
  color: #02187e;
  text-overflow: ellipsis;
}

.joinflock-container {
  margin-top: -9rem;
  height: 280px;
  width: 100%;
  z-index: 1001;
  background-color: #02187e;
}

.flock-container {
  margin-top: 6rem;
  background-image: url(../../assets/shape3.svg);
  height: 99px;
  width: 293.66px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.flock-container h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  width: 149px;
  height: auto;
  color: #2148ff;
}

@media only screen and (max-width: 950px) {
  .content {
    flex-direction: column;
  }

  .image-container {
    margin-top: 8rem;
  }

  .desc-container {
    margin-top: -3rem;
  }

  .joinflock-container {
    height: 665px;
    margin-top: -30rem;
  }

  .flock-container {
    margin-top: 30rem;
  }
}

@media only screen and (max-width: 680px) {
  .image-container {
    margin-top: 5rem;
  }

  .img-hero {
    width: 240px;
  }

  .joinflock-container {
    height: 706px;
  }

  .desc-container .desc {
    max-width: 546px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .desc-container {
    background-image: url(../../assets/hero-mobile-bg.png);
    padding: 0rem 1rem 1rem 2.5rem;
    max-width: 600px;
    height: 576px;
  }

  .flock-container {
    margin-top: 32rem;
  }
}
