.farttime-container {
  width: 100%;
  background-color: #02187e;
}

.content-farttime {
  width: 100%;
}

.content-farttime h3 {
  font-size: 96px;
  line-height: 80px;
  font-weight: 400;
  text-align: center;
  color: #ff93c7;
  padding: 2.5rem 0.5rem 0 0.5rem;
}

.boxes-farttime {
  padding: 3rem 0.5rem 5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.box-farttime {
  background-image: url(../../assets/shape6.svg);
  background-repeat: no-repeat;
  width: 331px;
  height: 372px;
  display: flex;
  align-items: flex-start;
  background-size: contain;
  flex-direction: column;
  border-radius: 1rem;
  padding: 2rem 3rem 7rem 3rem;
  background-size: cover;
  gap: 1rem;
}

.box-farttime h1 {
  color: #02187e;
  font-size: 36px;
  font-weight: 400;
  line-height: 32px;
}

.box-farttime p {
  color: #2148ff;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
}

@media only screen and (max-width: 1000px) {
  .boxes-farttime {
    display: flex;
    flex-wrap: wrap;
  }

  .box-farttime {
    background-image: url(../../assets/shape6.svg);
    background-repeat: no-repeat;
    width: 300px;
    height: 400px;
    display: flex;
    padding: 2rem 2rem;
    gap: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .content-farttime h3 {
    font-size: 70px;
    text-align: left;
  }
}
